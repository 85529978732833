import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { v4 as uuid } from "uuid";

import { useFormsApi } from "@/helpers/useFormsApi";
import { useAppStore } from "@/stores/appStore";
import { useUserStore } from "@stores/userStore";
import { setupField } from "@/helpers/forms";
import { ApiFetchFormResponse, ApiFetchFormsResponse } from "@/types/ApiTypes";
import {
  FormAnswerIssueImageIssueType,
  FormAnswerIssueImageType,
  FormAnswerType,
} from "@/types/FormAnswerTypes";
import { FieldPhotoType, FormQuestionTypes } from "@/types/FormQuestionTypes";
import { deepMerge } from "@/helpers/helpers";
import {
  CondensedFormDataType,
  FormDataType,
  FormGroupType,
} from "@/types/FormTypes";
import { FormStatus } from "@/enums/Form";

dayjs.extend(utc);
dayjs.extend(advancedFormat);

const { getFormsRequest, getFormRequest, saveFormRequest, submitFormRequest } =
  useFormsApi();

export let useFormStore = defineStore("forms", {
  state: (): FormState => ({
    forms: {
      data: [],
    },
    form: {
      data: {
        id: 0,
        title: "",
        plot_id: 0,
        order: 0,
        group: "",
        completable_by: [],
        readable_by: [],
        unlocked_at: null,
        expiration_date: null,
        updated_at: null,
        created_at: "",
        completed_at: null,
        signature: {
          type: "signature",
          created_at: "",
          updated_at: null,
          order: 1,
          title: null,
          subtitle: null,
          settings: {
            header_id: 1,
            required: true,
          },
        },
        terms_conditions: null,
        questions: [],
        answers: {
          id: 0,
          signature: null,
          created_at: null,
          updated_at: null,
          answers: [],
        },
      },
    },
  }),

  persist: true,

  getters: {
    getForms(): FormGroupType[] {
      let forms = [] as FormGroupType[];
      const user = useUserStore();
      forms = this.getOrderedForms;

      /**
       * Filter forms the current user cannot read
       * This should be redundant as the API
       * will take care of this but it's
       * a good double check to have
       */
      for (const key in forms) {
        let result = forms[key].data.filter((form) =>
          user.hasRole(form.readable_by),
        );

        forms[key].data = result;
      }

      return forms;
    },

    getOrderedForms: (state) => {
      for (const key in state.forms.data) {
        state.forms.data[key].data.sort((a, b) => a.order - b.order);
      }

      return state.forms.data;
    },

    getOrderedQuestions: (state) => {
      return state.form.data.questions.sort((a, b) => a.order - b.order);
    },

    getQuestions(): FormQuestionTypes[] {
      let questions = this.getOrderedQuestions;

      questions
        .map((field) => {
          return setupField(field) ?? null;
        })
        .filter((field) => {
          return field !== null;
        });

      return questions;
    },

    getQuestionById: (state) => (id: number) => {
      return state.form.data.questions.find((question) => question.id === id);
    },

    getAnswerIndex: (state) => (questionId: number) => {
      return state.form.data.answers.answers.findIndex(
        (answer) => answer.question_id === questionId,
      );
    },

    getAnswerByQuestionId: (state) => (questionId: number) => {
      return state.form.data.answers.answers.find(
        (answer) => answer.question_id === questionId,
      );
    },
  },

  actions: {
    async fetchForms(plotId: string, params: object = {}) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const forms = await getFormsRequest(plotId, params);

        this.forms = forms.data;
      } catch (error: any) {
        console.error("Error getting forms: ", error);
        throw new Error("Error getting forms: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async fetchForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const form = await getFormRequest(id, plotId);

        if (form.status === 200) {
          this.form = form.data;
        } else {
          console.error("Error getting form: ", form);
        }
      } catch (error: any) {
        this.clearSelectedForm();
        throw new Error("Error getting form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async saveForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const save = await saveFormRequest(
          id,
          plotId,
          this.createFormSubmitObject(),
        );

        if (save.status === 200) {
          return save;
        } else {
          console.error("Error saving form: ", save);
          throw new Error("Error saving form: " + save);
        }
      } catch (error: any) {
        console.error("Catch Error saving form: ", error);
        throw new Error("Error saving form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    async submitForm(id: string, plotId: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      try {
        const submitForm = await submitFormRequest(
          id,
          plotId,
          this.createFormSubmitObject(),
        );

        if (submitForm.status === 200) {
          return submitForm;
        } else {
          console.error("Error submitting form: ", submitForm);
          throw new Error("Error submitting form: " + submitForm);
        }
      } catch (error: any) {
        console.error("Error submitting form: ", error);
        throw new Error("Error submitting form: " + error);
      } finally {
        appStore.setLoading(false);
      }
    },

    createFormSubmitObject() {
      return JSON.parse(
        JSON.stringify({
          answers: this.form.data.answers.answers,
        }),
      );
    },

    createNewAnswer(questionId: number): FormAnswerType {
      return {
        uuid: uuid(),
        question_id: questionId,
        created_at: this.newDateStamp(),
        updated_at: this.newDateStamp(),
      };
    },

    findOrCreateAnswer(questionId: number): FormAnswerType {
      const answer = this.getAnswerByQuestionId(questionId);

      if (answer) {
        return answer;
      }

      this.form.data.answers.answers.push(this.createNewAnswer(questionId));

      return this.findOrCreateAnswer(questionId);
    },

    addOrUpdateAnswer(answer: FormAnswerType) {
      let newAnswer = this.findOrCreateAnswer(
        parseInt(answer.question_id as any),
      );

      const index = this.getAnswerIndex(parseInt(answer.question_id as any));

      newAnswer = {
        ...newAnswer,
        ...answer,
      };

      if (!newAnswer.created_at) {
        newAnswer.created_at = this.newDateStamp();
      } else {
        newAnswer.updated_at = this.newDateStamp();
      }

      if (index > -1) {
        this.form.data.answers.answers[index] = newAnswer;
      } else {
        this.form.data.answers.answers.push(newAnswer);
      }
    },

    clearSelectedForm() {
      this.form = {
        data: {
          id: 0,
          title: "",
          unlocked_at: null,
          expiration_date: null,
          group: "",
          completable_by: [],
          readable_by: [],
          updated_at: null,
          created_at: "",
          completed_at: null,
          plot_id: 0,
          order: 0,
          signature: {
            type: "signature",
            created_at: "",
            updated_at: null,
            order: 1,
            title: null,
            subtitle: null,
            settings: {
              header_id: 1,
              required: true,
            },
          },
          terms_conditions: null,
          questions: [],
          answers: {
            id: 0,
            signature: null,
            created_at: null,
            updated_at: null,
            answers: [],
          },
        },
      };
    },

    addOrUpdateIssueItem(
      questionId: string,
      uuid: string,
      currentIssue: FormAnswerIssueImageIssueType,
    ) {
      const answer = this.findOrCreateAnswer(
        parseInt(questionId),
      ) as FormAnswerIssueImageType;

      if (!answer?.answer_issues) {
        answer.answer_issues = [];
      }

      const index = answer.answer_issues.findIndex(
        (issue) => issue.uuid === uuid,
      );

      if (index === -1) {
        answer.answer_issues.push(currentIssue);
      } else {
        answer.answer_issues.splice(index, 1, currentIssue);
      }
    },

    findIssueItemAnswer(questionId: string): FormAnswerIssueImageType {
      const foundAnswer = this.form?.data?.answers?.answers?.find(
        (answer) => answer.question_id === parseInt(questionId),
      ) as FormAnswerIssueImageType;

      return foundAnswer;
    },

    getOrCreateIssueItem(
      questionId: string,
      uuid: string,
      disabled: string,
    ): FormAnswerIssueImageIssueType {
      const answer = this.findIssueItemAnswer(questionId);

      const foundIssue = answer?.answer_issues?.find(
        (issue) => issue.uuid === uuid,
      ) as FormAnswerIssueImageIssueType;

      if (foundIssue) {
        foundIssue.disabled = disabled === "true";

        return foundIssue;
      }

      return {
        uuid,
        updated_at: this.newDateStamp(),
        deleted_at: null,
        disabled: false,
        title: "",
        comment: "",
        answer_media_ids: [],
      };
    },

    createNewPhotoQuestion(settings: object = {}): FieldPhotoType {
      return deepMerge(
        {
          type: "photo",
          id: 491,
          order: 1,
          title: "Photos",
          subtitle: "",
          created_at: this.newDateStamp(),
          updated_at: this.newDateStamp(),
          settings: {
            required: true,
            disabled: false,
            min_files: 1,
            max_files: 10,
            file_types: ["image/jpeg", "image/png"],
            max_file_size: 8192,
          },
          answer: {
            uuid: uuid(),
            question_id: 1,
            created_at: this.newDateStamp(),
            updated_at: this.newDateStamp(),
            answer_media_ids: [],
          },
        },
        settings,
      );
    },

    newDateStamp() {
      return dayjs().utc().format("YYYY-MM-DD HH:mm:ss");
    },

    isFormLocked(form: CondensedFormDataType | FormDataType) {
      const unlockedDate = form.unlocked_at
        ? dayjs.utc(form.unlocked_at).toDate()
        : null;

      return unlockedDate && unlockedDate > dayjs.utc().toDate();
    },

    isFormCompleted(form: CondensedFormDataType | FormDataType) {
      return form.completed_at !== null;
    },

    isFormExpired(form: CondensedFormDataType | FormDataType) {
      const expirationDate = form.expiration_date
        ? dayjs.utc(form.expiration_date).toDate()
        : null;

      return expirationDate && expirationDate < dayjs.utc().toDate();
    },

    getStatus(form: CondensedFormDataType | FormDataType): FormStatus {
      const userStore = useUserStore();
      let status: FormStatus = FormStatus.Available;

      if (
        !userStore.hasRole(form.completable_by) &&
        userStore.hasRole(form.readable_by)
      ) {
        status = FormStatus.ReadOnly;
      }

      if (userStore.isStaff && userStore.hasRole(form.completable_by)) {
        return status;
      }

      if (this.isFormCompleted(form)) {
        status = FormStatus.Completed;
      }

      if (this.isFormLocked(form) || this.isFormExpired(form)) {
        status = FormStatus.Locked;
      }

      return status;
    },

    getStatusLabel(form: CondensedFormDataType | FormDataType) {
      const userStore = useUserStore();
      const status = this.getStatus(form);
      let label = "Available to complete";

      if (form.expiration_date) {
        label = `Available to complete by ${dayjs.utc(form.expiration_date).format("Do MMMM YYYY")}`;
      }

      if (status === FormStatus.Locked) {
        label = `Not yet available to complete`;

        if (form.unlocked_at) {
          label = `Available on ${dayjs.utc(form.unlocked_at).format("Do MMMM YYYY")}`;
        }

        if (this.isFormExpired(form)) {
          label = `Expired on ${dayjs.utc(form.expiration_date).format("Do MMMM YYYY")}`;
        }
      }

      if (status === FormStatus.Completed) {
        label = `Submitted ${dayjs.utc(form.completed_at).format("Do MMMM YYYY")}`;
      }

      if (status === FormStatus.ReadOnly) {
        label = "Available to view";
      }

      if (userStore.isStaff) {
        if (this.isFormLocked(form)) {
          label = "Locked for the customer";
        }

        if (this.isFormCompleted(form)) {
          label = `Submitted ${dayjs.utc(form.completed_at).format("Do MMMM YYYY")}`;
        }
      }

      return label;
    },

    getFormIcon(form: CondensedFormDataType | FormDataType) {
      const status = this.getStatus(form);
      let icon = "arrowRight";

      if (status === FormStatus.Locked) {
        icon = "icon-lock";
      }

      if (status === FormStatus.Completed) {
        icon = "icon-tick";
      }

      return icon;
    },
  },
});

interface FormState {
  forms: ApiFetchFormsResponse;
  form: ApiFetchFormResponse;
}
