import { defineStore } from "pinia";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAppStore } from "./appStore";
import { useNotificationsApi } from "@/helpers/useNotificationsApi";
import { NotificationType } from "@/types/NotificationsTypes";
import { ApiFetchNotificationResponse } from "@/types/ApiTypes";
import { h } from "vue";

const { getNotificationsRequest, readNotificationsRequest } =
  useNotificationsApi();

dayjs.extend(utc);

export let useNotificationsStore = defineStore("notifications", {
  state: (): NotificationsState => ({
    notifications: {
      data: [],
      links: {
        first: null,
        last: null,
        prev: null,
        next: null,
      },
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        links: [],
        path: "",
        per_page: 1,
        to: 1,
        total: 1,
      },
    },
  }),

  persist: true,

  actions: {
    async fetchNotifications(params: object = {}) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const notifications = getNotificationsRequest(params);

      notifications
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async readAllNotifications() {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const request = readNotificationsRequest();

      request
        .then((response) => {
          if (response.status === 200) {
            this.notifications = response.data.data;
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    async readNotification(id: string) {
      const appStore = useAppStore();

      appStore.setLoading(true);

      const request = readNotificationsRequest([id]);

      request
        .then((response) => {
          if (response.status === 200) {
            this.fetchNotifications();
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          appStore.setLoading(false);
        });
    },

    getDate(date: string) {
      return dayjs.utc(date).format("D MMMM");
    },
  },

  getters: {
    hasNotifications(state) {
      return state.notifications.data.length > 0;
    },

    unreadNotifications(state) {
      return state.notifications.data.filter(
        (notification) => !notification.read_at,
      );
    },
  },
});

interface NotificationsState {
  notifications: ApiFetchNotificationResponse;
}
