import axios from "@/helpers/useApi";
import { apiUrl } from "@/helpers/apiConfig"; // Import the apiUrl constant

export function useNotificationsApi() {
  const getNotificationsRequest = async (params: object = {}) => {
    const queryParams = {
      page: 1,
      ...params,
    };

    try {
      return await axios.get(`${apiUrl}/api/notifications`, {
        params: queryParams,
      });
    } catch (error: any) {
      console.error("Error getting notifications:", error);

      throw error.response.data.message;
    }
  };

  const readNotificationsRequest = async (ids?: string[]) => {
    let body = {
      all: true,
      ids,
    };

    if (ids) {
      body.all = false;
    }

    try {
      return await axios.post(`${apiUrl}/api/notifications/read`, body);
    } catch (error: any) {
      console.error("Error setting notifications to read:", error);

      throw error.response.data.message;
    }
  };

  return {
    getNotificationsRequest,
    readNotificationsRequest,
  };
}
