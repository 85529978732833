import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 104 50"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M49 0v22h5.178C55.644 9.742 65.981.209 78.587.004L79 0c13.807 0 25 11.193 25 25S92.807 50 79 50c-13.472 0-24.455-10.656-24.98-24H49v23H0V0zm30 4c-11.598 0-21 9.402-21 21s9.402 21 21 21 21-9.402 21-21S90.598 4 79 4M4 3.999V45h41V4zm74.693 5.528q5.45 0 8.457 2.871 3.009 2.872 3.34 6.524h-3.789q-.645-2.774-2.568-4.395-1.925-1.62-5.4-1.62-4.239 0-6.846 2.978t-2.608 9.13q0 5.04 2.354 8.174 2.354 3.135 7.021 3.135 4.297 0 6.543-3.3 1.192-1.74 1.778-4.57h3.789q-.508 4.53-3.36 7.597-3.417 3.69-9.218 3.691-5 0-8.399-3.027-4.473-4.004-4.473-12.363 0-6.348 3.36-10.41 3.633-4.415 10.02-4.415M14 9v13h20V9h4v30h-4V26H14v13h-4V9z"
    }, null, -1)
  ])))
}
export default { render: render }