import {
  NavigationGuardNext,
  createRouter,
  createWebHistory,
} from "vue-router";
import { useUserStore } from "@stores/userStore";
import { useAppStore } from "@stores/appStore";
import { useAuthStore } from "@/stores/authStore";
import { useFormStore } from "@/stores/formStore";

export const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
      next(isLoggedInRedirect());
    },
  },

  {
    path: "/logout",
    name: "logout",
    beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
      const authStore = useAuthStore();
      authStore.logout();

      next(isLoggedOutRedirect());
    },
    component: () => import("@/components/login/LoginForm.vue"),
  },

  {
    path: "/login/",
    children: [
      {
        path: "", // Empty path means it will be the default child route
        name: "login",
        component: () => import("@/components/login/LoginForm.vue"),
        // Add beforeEnter guard to redirect logged-in users to /account
        beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
          const formStore = useFormStore();
          formStore.$reset();

          next(isLoggedInRedirect());
        },
      },
      {
        path: "forgot/", // Path for the forgot-password route, nested under /login
        name: "forgot-password",
        component: () => import("@/components/login/ForgotPasswordForm.vue"),
      },
      {
        path: "reset/:token", // Path for the reset-password route, nested under /login
        name: "reset-password",
        component: () => import("@/components/login/ResetPasswordForm.vue"),
        beforeEnter: (
          to: any,
          from: any,
          next: (arg0?: { name: string } | undefined) => void,
        ) => {
          if (to.query.email) {
            next();
          } else {
            next({ name: "login" });
          }
        },
      },
    ],
  },

  {
    path: "/account/",
    name: "account",
    component: () => import("@/components/account/AccountWelcomeView.vue"),
    // Add beforeEnter guard to check if user is logged in
    meta: { requiresAuth: true, showBackLink: false },
    beforeEnter: (
      to: any,
      from: any,
      next: (arg0?: { name: string } | undefined) => void,
    ) => {
      const userStore = useUserStore();

      userStore.getProfile();

      next();
    },
  },

  {
    path: "/development/",
    children: [
      {
        path: "", // Empty path means it will be the default child route
        name: "development-select",
        component: () => import("@/views/login/DevelopmentSelectView.vue"),
        // Add beforeEnter guard to check if user is logged in
        meta: { requiresAuth: true, showBackLink: false },
        beforeEnter: (
          to: any,
          from: any,
          next: (arg0?: { name: string; params: object } | undefined) => void,
        ) => {
          const userStore = useUserStore();
          const appStore = useAppStore();
          const developments = appStore.getDevelopments();

          appStore.setLoading(true);

          if (userStore.data.id === 0) {
            userStore.getProfile();
          }

          developments
            .then(() => {
              if (appStore.developments) {
                if (appStore.developments.meta.total <= 1) {
                  appStore.setDevelopment(appStore.developments.data[0]);

                  next({
                    name: "plot-select",
                    params: { developmentId: appStore.development?.id ?? 0 },
                  });
                } else {
                  next();
                }
              }
            })
            .finally(() => {
              appStore.setLoading(false);
            });
        },
      },
      {
        path: ":developmentId/plots/",
        name: "plot-select",
        component: () => import("@/views/login/PlotSelectView.vue"),
        // Add beforeEnter guard to check if user is logged in
        meta: { requiresAuth: true, showBackLink: true },
        beforeEnter: (to: any, from: any, next: NavigationGuardNext) => {
          const userStore = useUserStore();
          const appStore = useAppStore();
          const developmentId = to.params.developmentId;
          const plots = appStore.getPlots(developmentId);

          if (userStore.data.id === 0) {
            userStore.getProfile();
          }

          plots.then(() => {
            if (!appStore.plots || appStore.plots.meta.total <= 0) {
              // Handle no plots on development
              appStore.setPlot(null);
              appStore.setDevelopment(null);
              next({ name: "development-select" });
            } else if (appStore.plots?.meta.total === 1) {
              // Handle 1 plot only on development
              appStore.setPlot(appStore.plots.data[0]);
              next(isLoggedInRedirect());
            } else {
              next();
            }
          });
        },
      },
    ],
  },

  {
    path: "/plot/:plotId/",
    children: [
      {
        path: "forms/",
        children: [
          {
            path: "", // Empty path means it will be the default child route
            name: "forms",
            component: () => import("@/views/FormsView.vue"),
            meta: { requiresAuth: true, showBackLink: false },
          },
          {
            path: ":formId/",
            children: [
              {
                path: "", // Empty path means it will be the default child route
                name: "form",
                component: () => import("@/views/FormView.vue"),
                meta: { requiresAuth: true, showBackLink: true },
              },
              {
                path: "issue/:questionId/:issueUuid",
                name: "issue-form",
                component: () =>
                  import("@/components/forms/fields/FieldIssueItemForm.vue"),
                meta: { requiresAuth: true, showBackLink: true },
              },
              {
                path: "question/:questionId",
                name: "single-field",
                component: () => import("@/views/SingleQuestionView.vue"),
                meta: { requiresAuth: true, showBackLink: true },
              },
            ],
          },
        ],
      },

      {
        path: "buyers-guide/",
        children: [
          {
            path: "", // Empty path means it will be the default child route
            name: "buyers-guide",
            component: () => import("@/views/BuyersGuideView.vue"),
            meta: { requiresAuth: true, showBackLink: false },
          },
          {
            path: ":guideId",
            name: "guide",
            component: () => import("@/views/SingleBuyerGuideView.vue"),
            meta: { requiresAuth: true, showBackLink: true },
          },
        ],
      },

      {
        path: "notifications/",
        name: "notifications",
        meta: { requiresAuth: true, showBackLink: false },
        component: () => import("@/views/NotificationsView.vue"),
      },
    ],
  },

  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundView.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "404" },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const appStore = useAppStore();

  // Handle clearing the titles when navigating to a new route
  appStore.clearTitle();

  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const redirectCallback = isLoggedOutRedirect();

    if (redirectCallback) {
      next(redirectCallback);
    } else {
      next(); // Allow access to the route
    }
  } else {
    next();
  }

  if (to.meta.showBackLink) {
    appStore.showBackLink = true;
  } else {
    appStore.showBackLink = false;
  }
});

export const isLoggedInRedirect = (): any => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    const appStore = useAppStore();

    return {
      name: "buyers-guide",
      params: { plotId: appStore.plot?.id ?? 0 },
    };
  }

  return; // Allow access to the route
};

export const isLoggedOutRedirect = (): any => {
  const authStore = useAuthStore();

  if (!authStore.isLoggedIn) {
    return { name: "login" }; // Redirect to the login route
  }

  return; // Allow access to the route
};
