<script setup lang="ts">
import AppMenuContainer from "@/components/app-menu/AppMenuContainer.vue";
import { router } from "@/router/router";
import { useAppStore } from "@/stores/appStore";
import { useNotificationsStore } from "@/stores/notificationStore";
import { useRoute } from "vue-router";

const appStore = useAppStore();
const notificationStore = useNotificationsStore();
const route = useRoute();

const emits = defineEmits(["saveForm", "submitForm"]);

const handleFormSave = () => {
  emits("saveForm");
};
</script>

<template>
  <header
    class="page-header group flex items-end bg-primary py-1 text-white grid-in-header lg:w-full lg:py-4 border-b border-b-transparent lg:border-b-grey/50"
  >
    <div class="container flex items-center justify-between gap-[20px]">
      <div class="flex flex-wrap items-center gap-[20px]">
        <a
          @click="router.go(-1)"
          class="w-[24px] lg:hidden"
          :class="{ hidden: !appStore.showBackLink }"
          title="Back"
        >
          <InlineSvg name="back" />

          <span class="sr-only">Back</span>
        </a>

        <RouterLink to="/" class="">
          <InlineSvg
            name="logo"
            class="header-logo h-[50px] max-h-[35px] lg:max-h-[50px]"
            alt="Harper Crewe logo"
          />

          <span class="sr-only">Harper Crewe</span>
        </RouterLink>

        <div
          class="flex min-w-[220px] items-center justify-between gap-4 lg:hidden"
        >
          <h1
            class="header-title basis-full !mb-0 text-secondary"
            v-if="appStore.title.main"
          >
            {{ appStore.title.main }}
          </h1>

          <div v-if="route.params.formId">
            <button
              type="button"
              class="font-bold"
              @click="handleFormSave"
              :disabled="appStore.isLoading"
            >
              Save
            </button>
          </div>

          <div v-if="route.name?.toString() === 'notifications'">
            <button
              type="button"
              class="font-bold text-sm text-secondary whitespace-nowrap"
              @click="notificationStore.readAllNotifications"
              :disabled="appStore.isLoading"
              v-if="notificationStore.hasNotifications"
            >
              Read all
            </button>
          </div>
        </div>
      </div>

      <AppMenuContainer class="hidden lg:block" />
    </div>
  </header>

  <AppMenuContainer class="relative grid-in-footer lg:hidden" />
</template>

<style>
:root {
  * {
    --scroll-position: v-bind(appStore.getScrollPositionPercent);
  }
}

.page-header {
  &:has(.header-title) {
    animation-name: page-header;
    animation-duration: 1s;
    animation-timing-function: linear;
    /* Bind the animation to scroll */
    animation-delay: calc(var(--scroll-position) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    margin-bottom: 0;

    @screen lg {
      animation: none;
    }
  }
}

@keyframes page-header {
  49% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: 53px;
  }

  100% {
    margin-bottom: 0;
  }
}

.header-logo {
  animation-name: header-logo;
  animation-duration: 1s;
  animation-timing-function: linear;
  /* Bind the animation to scroll */
  animation-delay: calc(var(--scroll-position) * -1s);
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @screen lg {
    animation: none;
  }
}

@keyframes header-logo {
  to {
    max-height: 25px;
  }
}

.header-title {
  animation-name: header-title;
  animation-duration: 1s;
  animation-timing-function: linear;
  /* Bind the animation to scroll */
  animation-delay: calc(var(--scroll-position) * -1s);
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  @screen lg {
    animation: none;
  }
}

@keyframes header-title {
  0% {
    margin-bottom: 1rem;
  }

  50% {
    margin-bottom: 0;
  }

  100% {
    flex-basis: auto;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
