import { Component } from "vue";
import logo from "@/assets/logo.svg?component";
import arrowLeft from "@/assets/icons/arrow-left.svg?component";
import arrowRight from "@/assets/icons/arrow-right.svg?component";
import bellIcon from "@/assets/icons/icon-bell.svg?component";
import chevronDown from "@/assets/icons/chevron-down.svg?component";
import calendarIcon from "@/assets/icons/icon-calendar.svg?component";
import formIcon from "@/assets/icons/icon-form.svg?component";
import guideIcon from "@/assets/icons/icon-guide.svg?component";
import homeIcon from "@/assets/icons/icon-home.svg?component";
import infoIcon from "@/assets/icons/icon-info.svg?component";
import lockIcon from "@/assets/icons/icon-lock.svg?component";
import tickIcon from "@/assets/icons/icon-tick.svg?component";
import plusIcon from "@/assets/icons/icon-plus.svg?component";
import settingsIcon from "@/assets/icons/icon-settings.svg?component";
import deleteIcon from "@/assets/icons/icon-delete.svg?component";
import cameraIcon from "@/assets/icons/icon-camera.svg?component";
import uploadIcon from "@/assets/icons/icon-upload.svg?component";
import closeIcon from "@/assets/icons/icon-close.svg?component";

export default <IconLibraryType>{
  logo: logo,
  back: arrowLeft,
  arrowLeft: arrowLeft,
  arrowRight: arrowRight,
  "arrow-right": arrowRight,
  "icon-bell": bellIcon,
  chevronDown: chevronDown,
  "icon-calendar": calendarIcon,
  "icon-form": formIcon,
  "icon-guide": guideIcon,
  "icon-home": homeIcon,
  "icon-info": infoIcon,
  "icon-lock": lockIcon,
  "icon-tick": tickIcon,
  "icon-plus": plusIcon,
  "icon-add": plusIcon,
  "icon-settings": settingsIcon,
  "icon-delete": deleteIcon,
  "icon-camera": cameraIcon,
  "icon-upload": uploadIcon,
  "icon-close": closeIcon,
};

export interface IconLibraryType {
  [key: string]: Component;
}
