import axios, { Axios, AxiosInstance, AxiosStatic } from "axios";
import { apiUrl, DEVICE_NAME } from "@/helpers/apiConfig"; // Import the apiUrl constant
import { useAuthStore } from "@/stores/authStore";

const instance = axios.create({
  baseURL: apiUrl,
});

let isRefreshing = false;

/**
 * Intercept all requests and add the token to the header
 */
instance.interceptors.request.use((config) => {
  if (["api/login", "api/logout"].includes(config.url ?? "")) {
    return config;
  }

  if (config.url?.includes("api/refresh")) {
    config.headers.Authorization = `Bearer ${useAuthStore().getRefreshToken}`;

    return config;
  }

  const authStore = useAuthStore();
  let token = authStore.getToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers["Device-Type"] = DEVICE_NAME;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !isRefreshing) {
      // Access token has expired, refresh it
      isRefreshing = true;

      try {
        const authStore = useAuthStore();
        const newAccessToken = await authStore.refreshLogin();
        // Update the request headers with the new access token
        error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;

        isRefreshing = false;
        // Retry the original request
        return instance(error.config);
      } catch (refreshError) {
        // Handle token refresh error
        throw refreshError;
      }
    }

    return Promise.reject(error);
  },
);

export default instance as AxiosInstance;
